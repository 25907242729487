@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-primary bg-gray-200;
  }
  .dropdown {
    @apply w-full lg:max-w-[296px] cursor-pointer;
  }
  .dropdown-btn {
    @apply flex h-[64px] items-center px-[18px] border rounded-lg;
  }
  .dropdown-icon-primary {
    @apply text-2xl mr-[18px] text-green-600;
  }
  .dropdown-icon-secondary {
    @apply text-2xl text-green-600 ml-auto;
  }
  .dropdown-menu {
    @apply px-6 py-8 text-[15px] space-y-6 shadow-md bg-white absolute w-full z-10 list-none rounded-b-lg;
  }
}

/* styles.css */
.dropdown-menu::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Round the scrollbar thumb */
}

.dropdown-menu::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color on hover */
}

.dropdown-menu::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px; /* Round the scrollbar track */
}


@media (min-width: 1024px) and (max-width: 1184px) {
  .hidden-between {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1184px) {
  .dropdown{
   width: 160px;
   display: flex;
   gap: 10px;
  }
}

